import React, { useEffect } from 'react';
import HeaderView from './Components/Header';
import HomePageView from './Pages/HomePage.view';
import AboutView from './Pages/About.view';
import DetailsView from './Pages/Details.view';
import ContactView from './Pages/Contact.view';
import FooterView from './Components/Footer';

function App() {

  useEffect(() => {
    const mobileNavToggleBtn = document.querySelector<HTMLButtonElement>('.mobile-nav-toggle');
    const navButtons = document.querySelectorAll<HTMLButtonElement>('.nav-btn');

    const mobileNavToggle = () => {
      console.log("clicked kya kya");
      document.body.classList.toggle('mobile-nav-active');
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.classList.toggle('bi-list');
        mobileNavToggleBtn.classList.toggle('bi-x');
      }
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener('click', mobileNavToggle);
    }
    
    navButtons.forEach((navBtn) => {
      navBtn.addEventListener('click', () => {
        // Close the mobile menu when any nav item is clicked
        document.body.classList.remove('mobile-nav-active');
        if (mobileNavToggleBtn) {
          mobileNavToggleBtn.classList.add('bi-list');
          mobileNavToggleBtn.classList.remove('bi-x');
        }
      });
    });
    
    // Cleanup the event listener on unmount
    return () => {
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener('click', mobileNavToggle);
      }
      navButtons.forEach((navBtn) => {
        navBtn.removeEventListener('click', mobileNavToggle);
      });
    };
  }, []);

  return (
    <>
      <HeaderView />
      <main className="main">
        <HomePageView />
        <AboutView />
        <DetailsView />
        <ContactView />
      </main>
      <FooterView />
    </>
  );
}

export default App;
