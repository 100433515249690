import React from "react";
const HomePageView: React.FC = () => {

  return (
    <section id="hero" className="hero section dark-background">
      <img src="template/assets/img/hero-bg-2.jpg" alt="" className="hero-bg" />

      <div className="container">
        <div className="row gy-4 justify-content-between">


          <div className="col-lg-6  d-flex flex-column justify-content-center" data-aos="fade-in">
            <h1 >Welcome to <span>SA Infolines</span></h1>
            <br />
            <br />
            <p>We serve quality product and thrive into excellence.
              We use latest technologies in development and structured and easily maintainable code which is effective in long run.</p>

            <p>
              We serve quality product and thrive into excellence. We use latest technologies in development and structured and easily maintainable code which is effective in long run. We design elegantly, use quality code in backend and performant database coding.
            </p>
            <p>
              We use AWS/Azure CI/CD model for development and deployment of code for which we have Azure/AWS certified developers and Architects who work for complete client satisfaction.
            </p>
          </div>
          <div className="col-lg-5 order-lg-last hero-img" data-aos="zoom-out" data-aos-delay="100">
            <img src="template/imgs/svg/handshake.svg" alt="Handshake svg" />
          </div>

        </div>
      </div>

      <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="wave1">
          <use href="#wave-path" x="50" y="3" />
        </g>
        <g className="wave2">
          <use href="#wave-path" x="50" y="0" />
        </g>
        <g className="wave3">
          <use href="#wave-path" x="50" y="9" />
        </g>
      </svg>

    </section>
  )
}

export default HomePageView;