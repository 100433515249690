import axios from "axios";
import IEmailData from "../interfaces/Interfaces";

const sendEmail = async (email: IEmailData) => {
    const fnURL: string = process.env.REACT_APP_AZ_FN_URL ?? "https://api.sainfolines.com/api/SendEmailFunction?code=IAMZNbVkdXZ-XqRCS5MCc5cZJca7hbKt9DWRYDt6B3urAzFu9lgvqg==";
    try {
        const response = await axios.post(fnURL,
            email,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

export default sendEmail;