const FooterView = () => {

    return (
        <footer id="footer" className="footer dark-background">
            <div className="container copyright text-center mt-4">
                <p>© <span>Copyright</span> <strong className="px-1 sitename">SA Infolines</strong> <span>All Rights Reserved</span></p>
                <div className="credits">
                    Designed by <a href="https://sainfolines.com/">SA Infolines</a>
                </div>
            </div>

        </footer>
    )
}

export default FooterView;