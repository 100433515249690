import React from "react";

const WaitSpinner = (): JSX.Element => {


    return (
        <div className="spinner-border text-info" role="status">
        </div>
    )

}

export default WaitSpinner;