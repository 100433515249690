import React from "react";
const DetailsView: React.FC = () => {
    return (
        <section id="details" className="details section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Our Work</h2>
                <div><span>Check Our</span> <span className="description-title">Work</span></div>
                <p>
                    We develop customized Softwares with the quality standards requirements
                </p>
            </div>

            <div className="container">

                <div className="row gy-4 align-items-center features-item">
                    <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                        <img src="./template/imgs/svg/connected-world.svg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
                        <h3>Supply Chain Management</h3>
                        <p className="fst-italic">
                            Every organization needs to buy things, but without a proper trail, the likelihood of fraud vastly increases. Boosts the purchasing process by enabling instant requisition creation.
                        </p>
                        <ul>
                            <li><i className="bi bi-check" /><span> Streamline Purchase Requisitions</span></li>
                            <li><i className="bi bi-check" /> <span>Value-based Vendor Management</span></li>
                            <li><i className="bi bi-check" /> <span>Simple Quotation Management</span></li>
                            <li><i className="bi bi-check" /> <span>Purchase Order Flexibility</span></li>
                            <li><i className="bi bi-check" /> <span>Meticulous Invoice Management</span></li>
                            <li><i className="bi bi-check" /> <span>MRP and Production Planning with LOGIC ERP Manufacturing Software</span></li>
                            <li><i className="bi bi-check" /> <span>Manage Multiple Stores, Warehouses, Point of Sales with LOGIC ERP</span></li>
                            <li><i className="bi bi-check" /> <span>Robust, Scalable and Flexible ERP Software Solution</span></li>
                        </ul>
                    </div>
                </div>

                <div className="row gy-4 align-items-center features-item">
                    <div className="col-md-5 order-1 order-md-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                        <img src="./template/imgs/svg/learning.svg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-7 order-2 order-md-1" data-aos="fade-up" data-aos-delay="200">
                        <h3>Learning Stations</h3>
                        <p className="fst-italic">
                            Improving lives through learning
                        </p>
                        <p>
                            Our curated collection of business and technical courses help companies, governments, and nonprofits go further by placing learning at the center of their strategies.
                        </p>
                        <ul>
                            <li><i className="bi bi-check" /> <span>School Information Management System</span></li>
                        </ul>
                    </div>
                </div>

                <div className="row gy-4 align-items-center features-item">
                    <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out">
                        <img src="./template/imgs/svg/erp.svg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-7" data-aos="fade-up">
                        <h3>ERP Software</h3>
                        <p>We develop customized ERP inline with the quality standards requirements</p>
                        <ul>
                            <li><i className="bi bi-check" /> <span>Increased competitiveness with integrated, fast, and flexible business processes</span></li>
                            <li><i className="bi bi-check" /> <span>Accelerated time to market with innovative, individualized products and services</span></li>
                            <li><i className="bi bi-check" /> <span>Simplified corporate structure, market channel, and business scenario management</span></li>
                            <li><i className="bi bi-check" /> <span>Improved corporate resource and asset utilization – and greater customer satisfaction</span></li>
                            <li><i className="bi bi-check" /> <span>Consolidated foundation for the latest mobile, cloud, and in-memory technologies</span></li>
                            <li><i className="bi bi-check" /> <span>Trusted support for business processes in over 15 industries</span></li>
                        </ul>
                    </div>
                </div>

                <div className="row gy-4 align-items-center features-item">
                    <div className="col-md-5 order-1 order-md-2 d-flex align-items-center" data-aos="zoom-out">
                        <img src="./template/imgs/svg/static-website.svg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-7 order-2 order-md-1" data-aos="fade-up">
                        <h3>Creative and Engaging Static Websites</h3>
                        <p className="fst-italic">

                        </p>
                        <p>
                            I specialize in crafting visually appealing and user-friendly static websites that showcase your unique personality or business. With a focus on clean design and clear navigation, I'll create a website that leaves a lasting impression.
                        </p>
                        <p>
                            Every individual or business deserves a website that reflects their brand identity. I work closely with you to understand your goals and create a custom website that aligns with your vision.
                        </p>
                        <p>
                            Even after your website is launched, I'm here to provide ongoing support and maintenance. From updates to troubleshooting, I'll ensure your website remains functional and up-to-date.
                        </p>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default DetailsView;